let awsRegion = 'ap-southeast-2';

export default {
 api_urls: {
   reporting: 'https://ivw74c786g.execute-api.ap-southeast-2.amazonaws.com/api',
   geojson: 'https://qeqkff2eh9.execute-api.ap-southeast-2.amazonaws.com/api',
   overlays: 'https://o50vxqth2g.execute-api.ap-southeast-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.staging.nsr.forwoodsafety.com',
 forwood_id_url: 'https://id.staging.nsr.forwoodsafety.com?redirect_uri=https://geoeditor.staging.nsr.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.staging.nsr.forwoodsafety.com',
 Auth: {
   userPoolId: 'ap-southeast-2_IpiiUgpqZ',
   userPoolWebClientId: '4f500mpir5rt3f9jb0ls92unar',
   cookieStorage: {
     domain: '.staging.nsr.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
